"use client";
import { SolutionCard } from "../SolutionCard";
import { solutions } from "../data/solutions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 640 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export function OurSolutions() {
  return (
    <section className="flex justify-center items-center px-16 py-20 w-full bg-white max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col mb-6 w-full max-w-[1170px] max-md:max-w-full">
        <h2 className="text-4xl font-bold text-center leading-[52px] max-md:max-w-full text-brand-primary-2">
          Our <span className="">Solutions</span>
        </h2>
        <div className="mt-20 max-md:mt-10 max-md:max-w-full">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            arrows={false}
            swipeable={true}
            draggable={true}
            showDots={true}
            keyBoardControl={true}
            customTransition="all 1"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["mobile"]}
            dotListClass="pt-8"
            itemClass="px-4 mb-16 md:mb-8"
          >
            {solutions.map((solution, index) => (
              <SolutionCard
                imageSrc={solution.imageSrc}
                iconSrc={solution.iconSrc}
                title={solution.title}
                description={solution.description}
                ctaText="Find out more"
                ctaLink={solution.href}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
