
export type CaseStudyProps = {
  href: string;
  imageSrc: string;
  logoSrc: string;
  description: string;
  stats: Array<{ title: string; description: string }>;
  features?: string[];
};

export const caseStudies: CaseStudyProps[] = [
  {
    href: "/case-study/x-games",
    imageSrc: `/case-studies/x-games.jpg`,
    logoSrc: `/case-studies/x-games-logo.jpg`,
    description:
      "X Games athletes are able to get paid quickly and compliantly, no matter which country they are from.",
    stats: [
      {
        title: "Payout Triumph",
        description:
          "Hundreds of athletes seamlessly onboarded and rapidly paid.",
      },
      {
        title: "21 countries",
        description:
          "21 countries received payments and 12 currencies paid with cross-border compliance.",
      },
    ],
  },
  {
    href: "/case-study/cuesports/",
    imageSrc: `/case-studies/cue-sports.jpg`,
    logoSrc: `/case-studies/cue-sports-logo.jpg`,
    description:
      "Payment Labs replaced CueSports International (CSI)'s manual payout process with an easy and automated one that delighted winners at the 2024 CueSports International Expo in Vegas.",
    stats: [
      {
        title: "Payout Success",
        description:
          "New electronic payout process replaces checks and a legacy payout process.",
      },
      {
        title: "995 Athletes",
        description: "CSI made 1213 payments to 995 athletes.",
      },
    ],
    features: ["Global Payments", "Prize Payments"],
  },
  {
    href: "/case-study/evolution-championship-series/",
    imageSrc: `/case-studies/evo.jpg`,
    logoSrc: `/case-studies/evo-logo.jpg`,
    description:
      "Payment Labs simplifies payouts for major esports events with a platform crafted by gaming and esports veterans.",
    stats: [
      {
        title: "Payout Support",
        description:
          "Payment professionals onsite in Vegas to answer questions and quickly payout winners.",
      },
      {
        title: "48 Countries",
        description:
          "EVO made 514 payments in 33 currencies across 48 countries.",
      },
    ],
    features: ["Global Payments", "Prize Payments"],
  },
  {
    href: "/case-study/liquiddogs/",
    imageSrc: `/case-studies/liquid-dogs.jpg`,
    logoSrc: `/case-studies/liquid-dogs-logo.jpg`,
    description:
      "Payment Labs simplifies payouts for major esports events with a platform crafted by gaming and esports veterans.",
    stats: [
      {
        title: "Quick Payouts",
        description: "Compliant global gamer payouts distributed quick.",
      },
      {
        title: "725 Gamers",
        description:
          "LiquidDogs made 1863 payments in 42 currencies across 58 countries.",
      },
    ],
    features: ["Global Payments", "Prize Payments"],
  },
  {
    href: "/case-study/app-pickleball/",
    imageSrc: `/case-studies/app.jpg`,
    logoSrc: `/case-studies/app-logo.jpg`,
    description:
      "454 athletes paid across 11 tournaments with 38 hours of average payment delivery time from onboarding to payment.",
    stats: [
      {
        title: "Simplified Payouts",
        description:
          "Automation of payout and tax form collection to scale up pickleball events.",
      },
      {
        title: "454 Athletes",
        description:
          "The Association of Pickleball players (APP) simplified it's payments and tax form collection process.",
      },
    ],
    features: ["Global Payments", "Prize Payments"],
  },
  {
    href: "/case-study/medianug/",
    imageSrc: `/case-studies/media-nug.jpg`,
    logoSrc: `/case-studies/media-nug-logo.jpg`,
    description:
      "MediaNug distributes 725 payments across 9 countries and 5 currencies with fast payments & streamlined tax compliance in the creator economy.",
    stats: [
      {
        title: "Global Contractors",
        description: "Timely, Secure, and Compliant Payments to Contractors.",
      },
      {
        title: "2-5x faster",
        description: "725 payments paid across 9 countries in 5 currencies.",
      },
    ],
    features: ["Global Payments"],
  },
  {
    href: "/case-study/arnold-sports/",
    imageSrc: `/case-studies/arnold-sports.png`,
    logoSrc: `/case-studies/arnold-sports-logo.png`,
    description:
      "Arnold Sports paid out 2 million dollars, making 138 payments in under 7 days.",
    stats: [
      {
        title: "Global Contractors",
        description: "Timely, Secure, and Compliant Payments to Contractors.",
      },
      {
        title: "2-5x Faster",
        description: "138 payments paid across 9 countries in 5 currencies.",
      },
    ],
    features: ["Global Payments"],
  },
];
